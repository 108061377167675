import Projects from './Projects';
import AboutMe from './AboutMe';

const Main = ()=>{
    return (
	<main>
	    <AboutMe/> 
	    <Projects/>
	</main>
    );
}

export default Main;
